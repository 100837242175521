'use client'
import React from 'react'

import Image from "next/image";

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"



export function RememberBeforeBuyDomain({site_name}) {

    const t = useTranslations("sixThingsToRememberSection")

    const benefitData = [
        {
            id: 1,
            imageSrc: "/icons/home/keep-it-short.svg",
            title: t("thing-1-title", { name: site_name } ),
            description: t("thing-1-desc" , { name: site_name })
        },
        {
            id: 2,
            imageSrc: "/icons/home/brand-name.svg",
            title: t("thing-2-title", { name: site_name } ),
            description: t("thing-2-desc" , { name: site_name })
        },
        {
            id: 3,
            imageSrc: "/icons/home/less-is-more.svg",
            title: t("thing-3-title", { name: site_name } ),
            description: t("thing-3-desc" , { name: site_name })
        },
        {
            id: 4,
            imageSrc: "/icons/home/act-fast.svg",
            title: t("thing-4-title", { name: site_name } ),
            description: t("thing-4-desc" , { name: site_name })
        },
        {
            id: 5,
            imageSrc: "/icons/home/think-locally.svg",
            title: t("thing-5-title", { name: site_name } ),
            description: t("thing-5-desc" , { name: site_name })
        },
        {
            id: 6,
            imageSrc: "/icons/home/domain-availability.svg",
            title: t("thing-6-title", { name: site_name } ),
            description: t("thing-6-desc" , { name: site_name })
        },
    ];


    return (
        <section className="max-w-7xl lg:px-8 px-6 mx-auto" {...AosAnimation.RememberBeforeBuyDomain}>
            <h3 className="lg:text-3xl md:text-2xl text-xl  text-center font-bold leading-6 text-mainDarkPurple">
            {t("title", { name: site_name })} 
            </h3>
            <div className='flex justify-center flex-row flex-wrap items-center max-w-[1296px] w-full mx-auto px-2 py-4 gap-4 gap-y-12 mt-8'>

                {benefitData?.map(item => (
                    <div key={item.id} className="xl:w-[30%] lmd:w-[48%] w-full flex items-center justify-center flex-col gap-4 px-4 py-8">
                        <Image src={item.imageSrc} width={82} height={82} alt="image" className="" />
                        <h3 className='text-mainDarkPurple font-bold text-2xl text-center md:whitespace-nowrap'>
                            {item.title}
                        </h3>
                        <p className='text-mainDarkPurple font-normal text-base text-center lg:min-h-[144px] md:min-h-[168px]'>{item.description}</p>
                    </div>
                ))}

            </div>
        </section>
    )
}
