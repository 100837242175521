'use client'
import React from 'react';

import Image from 'next/image'

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"

const openTawkToChat = () => {
    if (window.Tawk_API) {
        window.Tawk_API.toggle();
    }
};

const mailTo = () => {
    window.location.href = 'mailto:support@sayt.az';
};

const callNumber = () => {
    window.location.href = 'tel:+994703300004';
};


export function NeedHelp({ site_name }) {

    const t = useTranslations("needHelpSection")

    const contentData = [
        {
            title: t("thing-1-title"),
            description: t("thing-1-desc"),
            icon: "live-chat-icon.svg",
            function: openTawkToChat
        },
        {
            title: t("thing-2-title"),
            description: t("thing-2-desc"),
            icon: "email-icon.svg",
            function: mailTo
        },
        {
            title: t("thing-3-title"),
            description: t("thing-3-desc"),
            icon: "phone-icons.svg",
            function: callNumber
        },
    ];


    return (
        <section className=" mx-auto sm:py-10 md:py-20 " {...AosAnimation.NeedHelp}>
            <div className="flex justify-between lmd:flex-row flex-col items-center w-full mx-auto bg-[#7D3BE2] ">

                <div className="xl:w-3/5 lg:w-1/2 min-h-[600px] w-full flex items-center justify-center xl:py-20 py-8 xl:px-15 px-6  flex-col gap-4 bg-[#E9F8F1]">

                    <Image src={`/assets/images/need-help.png`} className="max-h-[500px] " width={500} height={500} alt="hero" />


                </div>

                <div className="xl:w-2/5 lg:w-1/2 w-full flex flex-col md:justify-start justify-center md:items-start items-center gap-4 xl:px-16 lg:px-12 md:px-8 px-4 lmd:py-0  py-20">

                    <div>
                        <h2 className='text-white font-bold xl:text-4xl lg:text-3xl text-2xl'>
                            {t("title", { name: site_name })}
                        </h2>
                        {contentData.map((item, index) => (
                            <div key={index} className='flex items-start gap-1'>
                                <Image src={`/icons/home/${item.icon}`} className="w-8 h-8  mt-6" width={32} height={32} alt="hero" priority />
                                <div className="py-4 px-4 group cursor-pointer" onClick={item.function}>
                                    <h3 className="text-white group-hover:text-gray-200 font-bold lmd:text-2xl text-lg mb-2 flex items-center gap-2">
                                        {item.title}
                                    </h3>
                                    <p className="text-white group-hover:text-gray-200 font-medium lmd:text-base text-sm ">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </section>
    )
}
