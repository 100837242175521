"use client";
export const HTMLWrapper = ({ htmlString, classes }) => {
  const defaultOne = "<div>Something went wrong</div>";

  return (
    <div
      className={classes}
      dangerouslySetInnerHTML={{ __html: htmlString ?? defaultOne }}
    ></div>
  );
};
