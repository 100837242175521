'use client'
import React from 'react';

import Image from 'next/image'

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"


export function SupportForYourSuccess() {

    const t = useTranslations("supportForSuccessSection")


    return (
        <section className="max-w-7xl mx-auto sm:py-10 md:py-20 " {...AosAnimation.SupportForYourSuccess}>
            <div className="flex justify-between lmd:flex-row flex-col items-center w-full mx-auto ">

                <div className="lmd:w-1/2 w-full flex flex-col md:justify-start justify-center md:items-start items-center gap-4 xl:px-20 lg:px-16 md:px-10 px-4 lmd:py-0  py-20">

                    <div>
                        <h2 className='text-mainDarkPurple font-bold xl:text-5xl lg:text-4xl text-3xl'>
                        {t("title")}
                        </h2>
                        <div className='flex flex-col lg:gap-6 gap-3 md:px-4 px-0 lg:py-8 py-4 lg:max-w-[360px]'>
                            <p className='text-mainLightPurpleText font-medium text-base'>
                                {t("punk-1")}
                            </p>
                            <p className='text-mainLightPurpleText font-medium text-base'>
                                {t("punk-2")}
                            </p>
                        </div>
                    </div>
                    
                </div>        

                <div className="lmd:w-1/2 w-full flex items-start xl:py-20 py-8 xl:px-15 px-6 justify-start flex-col gap-4">
                    
                    <Image src={`/assets/images/24-support.jpeg`} className="w-full max-h-[500px] " width={552} height={400} alt="hero" />

                    
                </div>

            </div>
        </section>
    )
}
