'use client'
import React from 'react';

import Link from 'next/link';
import Image from "next/image";

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"

export function CreateWebSiteEasyStep() {

    const t = useTranslations("createWebSiteSection");

    const contentData = [
        {
            title: t("step-1-title"),
            description: t("step-1-desc"),
            icon: "click.svg"
        },
        {
            title: t("step-2-title"),
            description: t("step-2-desc"),
            icon: "credit-card.svg"
        },
        {
            title: t("step-3-title"),
            description: t("step-3-desc"),
            icon: "checkmark.svg"
        },
    ];


    return (
        <section className="max-w-7xl lg:px-8 px-6 mx-auto sm:py-16 md:py-20" {...AosAnimation.CreateWebSiteEasyStep}>
            <div className="flex justify-between lmd:flex-row flex-col items-center max-w-[1296px] w-full mx-auto px-2 py-4 gap-4 gap-y-8">

                <div className="lmd:w-1/2 w-full flex items-start justify-start flex-col gap-4">
                    <h2 className='text-mainDarkPurple font-bold xl:text-5xl lg:text-4xl text-3xl max-w-[440px] lg:leading-[60px] leading-8'>
                        {t("title")}
                    </h2>
                    <p className='text-mainTextPurple mt-4 font-medium'>
                        {t("desc")}
                    </p>
                    <div className="flex gap-3 flex-col md:flex-row mt-8">
                        <Link
                            href={'/website'}
                            className="bg-mainLightPurple text-white rounded-lg py-3 px-12 inline-block font-bold hover:bg-mainLightPurpleHover whitespace-nowrap">
                            {t('startLink')}
                        </Link>
                    </div>
                </div>

                <div className="lmd:w-1/2 w-full flex flex-col gap-4">
                    <div className=' divide-y-2'>
                        {contentData.map((item, index) => (
                            <div key={index} className='flex items-start '>
                                <Image src={`/icons/home/${item.icon}`} className="w-6 h-6 object-cover flex-shrink-0 mt-5" width={24} height={24} alt="hero" priority />
                                <div className="py-4 px-4 group " >
                                    <h3 className="text-mainDarkPurple group-hover:text-mainLightPurpleHover cursor-default font-bold lmd:text-2xl text-lg mb-2 flex items-center gap-2">
                                        {item.title}
                                    </h3>
                                    <p className="text-mainDarkPurpleText group-hover:text-mainLightPurpleHover cursor-default font-medium lmd:text-base text-sm max-w-[90%]">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </section>
    )
}
