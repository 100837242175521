import CountUp from "react-countup";

export function Counter({
  number,
  duration = 7,
  className,
  easing = "linear",
}) {
  return (
    <CountUp
      duration={duration}
      className={className}
      end={number}
      easing={easing}
    />
  );
}

