export const Loader = ({ fullscreen = true, size = 64 }) => {
  const containerClasses = fullscreen
    ? "flex h-screen items-center justify-center bg-white dark:bg-black overflow-hidden"
    : "flex items-center justify-center bg-white dark:bg-black overflow-hidden";

  const loaderClasses = `animate-spin rounded-full border-4 border-solid border-mainLightPurple border-t-transparent overflow-hidden`;

  return (
    <div className={containerClasses}>
      <div
        style={{ width: `${size}px`, height: `${size}px` }}
        className={loaderClasses}
      ></div>
    </div>
  );
};
