export const useConvertToHtml = () => {
    const convertToHtml = (value) => {
        const [price, frequence] = value.split("/")
        return (
            <div className="flex justify-center items-center gap-0.5">
                <span className="text-[20px] font-medium">{price}</span>
                <span className="self-end">{`/${frequence}`}</span>
            </div>
        )
    }

    return convertToHtml
}