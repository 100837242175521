'use client'
import React from 'react'

import Link from 'next/link'
import Image from 'next/image'

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl"


export function AllPlansForHome({ site_name }) {
    const t = useTranslations()
    return (
        <section className=" mx-auto sm:py-10 md:py-20 " {...AosAnimation.AllPlansForHome}>
            <div className="flex justify-between lmd:flex-row flex-col items-center w-full mx-auto bg-mainBgPurple ">
                <div className="lmd:w-3/5 w-full flex items-start xl:py-20 py-8 xl:px-15 px-6 justify-start flex-col gap-4 bg-gradient-to-r from-[#E3F8FD] to-[#FBE6FF]">
                    <Image src={`/assets/images/all-plans.png`} className="w-full max-h-[500px] " width={670} height={500} alt="hero" />
                </div>
                <div className="lmd:w-2/5 w-full flex flex-col md:justify-start justify-center md:items-start items-center gap-4 xl:px-20 lg:px-16 md:px-10 px-4 lmd:py-0  py-20">
                    <div>
                        <h2 className='text-mainDarkPurple font-bold xl:text-5xl lg:text-4xl text-3xl'>
                            {t("allPlansInclude", { name: site_name })}
                        </h2>
                    </div>
                    <div className="lg:mt-4 mt-2 ">
                        <Link
                            href={'/website'}
                            className="bg-mainLightPurple text-white rounded-lg py-3 px-12 font-bold hover:bg-mainLightPurpleHover whitespace-nowrap">
                            {t('startLink')}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
