export const defaultLang = async function getSiteInformation() {
  const res = await fetch(`${process.env.API_URL}/api/domain/our-domains`, {
    next: { revalidate: 100 },
  });

  return res.json();
}
export const data= {
  "hosting_packages": [
      {
          "id": 8,
          "name": "Plan Features",
          "description": "Plan Features",
          "cost": 5,
          "cost_yearly": 5,
          "package_options": []
      },
      {
          "id": 13,
          "name": "Starter",
          "description": "starter",
          "cost": 5,
          "cost_yearly": 60,
          "package_options": [
              {
                  "id": 1,
                  "value": "1",
                  "name": "Domain",
                  "type": "text"
              },
              {
                  "id": 2,
                  "value": "3 GB",
                  "name": "Storage",
                  "type": "text"
              },
              {
                  "id": 4,
                  "value": "3",
                  "name": "Email account",
                  "type": "text"
              },
              {
                  "id": 7,
                  "value": false,
                  "name": "Premium customer support",
                  "type": "boolean"
              },
              {
                  "id": 8,
                  "value": true,
                  "name": "Free SSL Security",
                  "type": "boolean"
              },
              {
                  "id": 12,
                  "value": true,
                  "name": "Backup and recovery",
                  "type": "boolean"
              },
              {
                  "id": 13,
                  "value": "Limitsiz",
                  "name": "Bandwidth",
                  "type": "text"
              },
              {
                  "id": 15,
                  "value": false,
                  "name": "Advanced Security Features",
                  "type": "boolean"
              },
              {
                  "id": 16,
                  "value": true,
                  "name": "cPanel",
                  "type": "boolean"
              },
              {
                  "id": 17,
                  "value": false,
                  "name": "FTP Access",
                  "type": "boolean"
              },
              {
                  "id": 18,
                  "value": false,
                  "name": "File Manager",
                  "type": "boolean"
              },
              {
                  "id": 19,
                  "value": false,
                  "name": "DNS Management",
                  "type": "boolean"
              },
              {
                  "id": 20,
                  "value": false,
                  "name": "Cron Jobs",
                  "type": "boolean"
              },
              {
                  "id": 21,
                  "value": false,
                  "name": "SSH Access",
                  "type": "boolean"
              }
          ]
      },
      {
          "id": 14,
          "name": "Basic",
          "description": "Basic",
          "cost": 10,
          "cost_yearly": 100,
          "package_options": [
              {
                  "id": 1,
                  "value": "2",
                  "name": "Domain",
                  "type": "text"
              },
              {
                  "id": 2,
                  "value": "8 GB",
                  "name": "Storage",
                  "type": "text"
              },
              {
                  "id": 4,
                  "value": "5",
                  "name": "Email account",
                  "type": "text"
              },
              {
                  "id": 7,
                  "value": false,
                  "name": "Premium customer support",
                  "type": "boolean"
              },
              {
                  "id": 8,
                  "value": true,
                  "name": "Free SSL Security",
                  "type": "boolean"
              },
              {
                  "id": 12,
                  "value": true,
                  "name": "Backup and recovery",
                  "type": "boolean"
              },
              {
                  "id": 13,
                  "value": "Limitsiz",
                  "name": "Bandwidth",
                  "type": "text"
              },
              {
                  "id": 15,
                  "value": false,
                  "name": "Advanced Security Features",
                  "type": "boolean"
              },
              {
                  "id": 16,
                  "value": true,
                  "name": "cPanel",
                  "type": "boolean"
              },
              {
                  "id": 17,
                  "value": false,
                  "name": "FTP Access",
                  "type": "boolean"
              },
              {
                  "id": 18,
                  "value": false,
                  "name": "File Manager",
                  "type": "boolean"
              },
              {
                  "id": 19,
                  "value": false,
                  "name": "DNS Management",
                  "type": "boolean"
              },
              {
                  "id": 20,
                  "value": false,
                  "name": "Cron Jobs",
                  "type": "boolean"
              },
              {
                  "id": 21,
                  "value": false,
                  "name": "SSH Access",
                  "type": "boolean"
              }
          ]
      },
      {
          "id": 15,
          "name": "Professional",
          "description": "pro",
          "cost": 15,
          "cost_yearly": 180,
          "package_options": [
              {
                  "id": 1,
                  "value": "5",
                  "name": "Domain",
                  "type": "text"
              },
              {
                  "id": 2,
                  "value": "14 GB",
                  "name": "Storage",
                  "type": "text"
              },
              {
                  "id": 4,
                  "value": "Limitsiz",
                  "name": "Email account",
                  "type": "text"
              },
              {
                  "id": 7,
                  "value": true,
                  "name": "Premium customer support",
                  "type": "boolean"
              },
              {
                  "id": 8,
                  "value": true,
                  "name": "Free SSL Security",
                  "type": "boolean"
              },
              {
                  "id": 12,
                  "value": true,
                  "name": "Backup and recovery",
                  "type": "boolean"
              },
              {
                  "id": 13,
                  "value": "Limitsiz",
                  "name": "Bandwidth",
                  "type": "text"
              },
              {
                  "id": 15,
                  "value": false,
                  "name": "Advanced Security Features",
                  "type": "boolean"
              },
              {
                  "id": 16,
                  "value": true,
                  "name": "cPanel",
                  "type": "boolean"
              },
              {
                  "id": 17,
                  "value": false,
                  "name": "FTP Access",
                  "type": "boolean"
              },
              {
                  "id": 18,
                  "value": false,
                  "name": "File Manager",
                  "type": "boolean"
              },
              {
                  "id": 19,
                  "value": false,
                  "name": "DNS Management",
                  "type": "boolean"
              },
              {
                  "id": 20,
                  "value": false,
                  "name": "Cron Jobs",
                  "type": "boolean"
              },
              {
                  "id": 21,
                  "value": false,
                  "name": "SSH Access",
                  "type": "boolean"
              }
          ]
      }
  ],
  "package_options": [
      {
          "id": 1,
          "name": "Domain",
          "type": "text"
      },
      {
          "id": 2,
          "name": "Storage",
          "type": "text"
      },
      {
          "id": 4,
          "name": "Email account",
          "type": "text"
      },
      {
          "id": 7,
          "name": "Premium customer support",
          "type": "boolean"
      },
      {
          "id": 8,
          "name": "Free SSL Security",
          "type": "boolean"
      },
      {
          "id": 12,
          "name": "Backup and recovery",
          "type": "boolean"
      },
      {
          "id": 13,
          "name": "Bandwidth",
          "type": "text"
      },
      {
          "id": 15,
          "name": "Advanced Security Features",
          "type": "boolean"
      },
      {
          "id": 16,
          "name": "cPanel",
          "type": "boolean"
      },
      {
          "id": 17,
          "name": "FTP Access",
          "type": "boolean"
      },
      {
          "id": 18,
          "name": "File Manager",
          "type": "boolean"
      },
      {
          "id": 19,
          "name": "DNS Management",
          "type": "boolean"
      },
      {
          "id": 20,
          "name": "Cron Jobs",
          "type": "boolean"
      },
      {
          "id": 21,
          "name": "SSH Access",
          "type": "boolean"
      }
  ]
}
export const DRAG_VERIFY_STATUS = {
  IN_PROGRESS: "inProgress",
  FULL_FILLED: "fullFilled",
  TO_DO: "todo",
};
export const PASS_ICON = {
  HIDDEN_PASS: "eye-slash-icon",
  REVEAL_HIDDEN_PASS: "eye-icon",
};

// export const hiddenPages = ["/password-reset-gmail", "/new-password-reset", "/sign-up", "/sign-in", "/get-quote", "/get-quote/confirmation", "/vps-hosting/purchase", "/vps-hosting/purchase/confirmation", "/web-hosting/purchase", "/domain/purchase", "/domain/purchase/configuration", "/domain/purchase/configuration/confirmation", "/dedicated-server/purchase", "/dedicated-server/purchase/confirmation", "/terms"];

export const AosAnimation = {
  //  Struckture
  //   data-aos="fade-up"
  //   data-aos-offset="200"
  //   data-aos-delay="50"
  //   data-aos-duration="1000"
  //   data-aos-easing="ease-in-out"
  //   data-aos-mirror="true"
  //   data-aos-once="false"
  //   data-aos-anchor-placement="top-center"

  HeroHeaderBanner: {
    // "data-aos": "zoom-in",
    // "data-aos-mirror": "false",
    // "data-aos-once": "true",
    // "data-aos-delay" : "0",
    // "data-aos-duration" : "0"
  },
  Packages: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
    "data-aos-duration" : "500"
  },
  CreateWebSiteEasyStep: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  AllPlansForHome: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  RememberBeforeBuyDomain: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  SupportForYourSuccess: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  NeedHelp: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  BlogHomePage: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  StatisticStats: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  },
  CTASection: {
    "data-aos": "fade-up",
    "data-aos-mirror": "false",
    "data-aos-once": "false",
    "data-aos-delay" : "300",
  }
};


export const questionMarkElements = [
  "Websites",
  "Parked Domains",
  "Subdomains per account",
  "1-Click Installer",
  "Hotlink Protection",
  "SSH Access",
  "Free SSL Certificate",
  "99.9% Uptime Guarantee",
];

export const regions = [
  {
    id: "auto",
    name: "Auto",
  },
  {
    id: "uk",
    name: "Ukraine",
  },
];

export const OS_Versions = [
  {
    os: "ubuntu",
    versions: [
      {
        id: "111",
        name: "Ubuntu 16.04 64-bit",
      },
      {
        id: "112",
        name: "Ubuntu 18.04 64-bit",
      },
      {
        id: "113",
        name: "Ubuntu 20.04 64-bit",
      },
      {
        id: "114",
        name: "Ubuntu 22.04 64-bit",
      },
    ],
  },
  {
    os: "debian",
    versions: [
      {
        id: "222",
        name: "Debian 8 64-bit",
      },
      {
        id: "223",
        name: "Debian 9 64-bit",
      },
      {
        id: "224",
        name: "Debian 10 64-bit",
      },
      {
        id: "225",
        name: "Debian 11 64-bit",
      },
    ],
  },
  {
    os: "opensuse",
    versions: [
      {
        id: "333",
        name: "OpenSUSE 15.4 64-bit",
      },
    ],
  },
  {
    os: "centos",
    versions: [
      {
        id: "444",
        name: "CentOS 6 64-bit",
      },
      {
        id: "445",
        name: "CentOS 7 64-bit",
      },
      {
        id: "446",
        name: "CentOS 8 64-bit",
      },
      {
        id: "447",
        name: "CentOS 9 64-bit",
      },
    ],
  },
];

export const osData = [
  {
    id: "111",
    name: "Ubuntu 16.04 64-bit",
  },
  {
    id: "112",
    name: "Ubuntu 18.04 64-bit",
  },
  {
    id: "113",
    name: "Ubuntu 20.04 64-bit",
  },
  {
    id: "114",
    name: "Ubuntu 22.04 64-bit",
  },
  {
    id: "222",
    name: "Debian 8 64-bit",
  },
  {
    id: "223",
    name: "Debian 9 64-bit",
  },
  {
    id: "224",
    name: "Debian 10 64-bit",
  },
  {
    id: "225",
    name: "Debian 11 64-bit",
  },
  {
    id: "333",
    name: "OpenSUSE 15.4 64-bit",
  },
  {
    id: "444",
    name: "CentOS 6 64-bit",
  },
  {
    id: "445",
    name: "CentOS 7 64-bit",
  },
  {
    id: "446",
    name: "CentOS 8 64-bit",
  },
  {
    id: "447",
    name: "CentOS 9 64-bit",
  },
];

export const storage = [
  {
    id: "fuhi2347g",
    name: "32 GB",
  },
  {
    id: "hf23f23",
    name: "64 GB",
  },
];

export const memories = [
  {
    id: "ifh238gf",
    name: "3 GB",
  },
  {
    id: "fnjwieuf",
    name: "5 GB",
  },
];

export const cpu = [
  {
    id: "jnfug84",
    name: "1 Core",
  },
  {
    id: "io43u2g8",
    name: "2 Core",
  },
  {
    id: "837g3hf38",
    name: "3 Core",
  },
  {
    id: "fjhqeif3",
    name: "4 Core",
  },
  {
    id: "jdhui3wgf",
    name: "5 Core",
  },
  {
    id: "fh3g489hg4",
    name: "6 Core",
  },
  {
    id: "83uyt934hg",
    name: "7 Core",
  },
  {
    id: "haskfubw34",
    name: "8 Core",
  },
  {
    id: "hufh32948",
    name: "9 Core",
  },
  {
    id: "bvzzbuibre",
    name: "10 Core",
  },
  {
    id: "bxvjjfhef3",
    name: "11 Core",
  },
  {
    id: "nviusdbu43",
    name: "12 Core",
  },
  {
    id: "7834gf3gf9",
    name: "13 Core",
  },
  {
    id: "jhsafbi398",
    name: "14 Core",
  },
  {
    id: "8yfwehfkr",
    name: "15 Core",
  },
  {
    id: "hshgewr93",
    name: "16 Core",
  },
];

export const bandwidth = [
  {
    id: "3f9u823",
    name: "2 TB",
  },
  {
    id: "o3if324u",
    name: "3 TB",
  },
];

export const ipv4 = [
  {
    id: "73gg23fhg",
    name: "1 IP",
  },
  {
    id: "74yegjohif",
    name: "2 IP",
  },
];

export const ERROR_MESSAGES = {
  WENT_WRONG: "Something went wrong!",
  NOT_A_ROBOT: "Please verify that you are not robot!",
  NOT_DOMAIN_NAME: "Doman's name is not written",
};

export const Periods = [
  {
    id: 1,
    value: 1,
    title: 'month'
  },
  {
    id: 2,
    value: 2,
    title: 'inc_months'
  },
  {
    id: 3,
    value: 3,
    title: 'inc_months'
  },
  {
    id: 4,
    value: 4,
    title: 'inc_months'
  },
  {
    id: 5,
    value: 5,
    title: 'inc_months'
  },
  {
    id: 6,
    value: 6,
    title: 'inc_months'
  },
  {
    id: 12,
    value: 1,
    title: "year"
  },
  {
    id: 24,
    value: 2,
    title: "inc_years",
  },
  {
    id: 36,
    value: 3,
    title: "inc_years",
  },
];

export const currencyList = [
  {
    id: "USD",
    name: "USD",
  },
  {
    id: "AZN",
    name: "AZN",
  },
];

export const languages = [
  {
    id: "az",
    name: "AZ",
  },
  {
    id: "en",
    name: "EN",
  },
];
