"use client"
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import ReactPaginate from "react-paginate";
import { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resize } from "@/store"


export const Pagination = ({ currentPage=1, total }) => {
  const dispatch = useDispatch()
  const [pageRange, setPageRange] = useState(1);
  const [marginPages, setMarginPages] = useState(1);
  const { sizes } = useSelector(state => state.common)

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();


  const handleResize = useCallback(() => {
    dispatch(resize({ x: window.innerWidth, y: window.innerHeight }))
  }, [dispatch])

  const handlePageChange = event => {
    const current = new URLSearchParams(Array.from(searchParams.entries())); 
    const value = event.selected + 1

    if (!value) {
      current.delete("page");
    } else {
      current.set("page", value);
    }

    const search = current.toString();
    const query = search ? `?${search}` : "";

    router.push(`${pathname}${query}`);
  }

  useEffect(() => {
    handleResize()  
  }, [handleResize])

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    if (sizes?.x < 642) {
      setPageRange(1);
      setMarginPages(1);
      return;
    }

    setMarginPages(2);
    setPageRange(1);
  }, [sizes]);

  return (
    <div className="flex justify-center">
      {
        total > 1 
          ? <ReactPaginate
            breakLabel="..."
            className="flex"
            initialPage={currentPage - 1 || 0}
            breakClassName="text-black p-2 self-end"
            pageLinkClassName="w-full h-full grid place-items-center rounded-lg"
            activeLinkClassName="text-black bg-main text-white rounded-lg"
            pageClassName="border border-grey-200 w-12 h-12 rounded-lg text-slate-400 grid bg-white place-items-center m-1 hover:bg-blue-200 hover:text-white transition"
            nextLabel=">"
            nextClassName="border border-grey-200 w-12 h-12 rounded-lg text-slate-400 grid bg-white place-items-center m-1"
            onPageChange={handlePageChange}
            pageCount={total}
            pageRangeDisplayed={pageRange}
            marginPagesDisplayed={marginPages}
            previousLabel="<"
            renderOnZeroPageCount={null}
            previousClassName="border border-grey-200 w-12 h-12 rounded-lg text-slate-400 grid bg-white place-items-center m-1"
            previousLinkClassName="w-full h-full grid place-items-center"
            nextLinkClassName="w-full h-full grid place-items-center"
          />
          : ''
      }
    </div>
  );
};
