'use client'

import { useTranslations } from "next-intl"

import { Counter } from "./Global/Counter"
import { useGetStatisticsQuery } from "@/store"

import { AosAnimation } from "@/constants";

export function StatisticStats() {

  const t = useTranslations()
  const { data } = useGetStatisticsQuery()

  const { templates_count ,today_created_sites_count,monthly_created_sites_count,total_sites_hit_count} = data || {}

  const stats = [
    { name: t('countWebsiteToday'), stat: today_created_sites_count },
    { name: t('countWebsiteMonth'), stat: monthly_created_sites_count},
    { name: t('countHit'), stat: total_sites_hit_count },
    { name: t('countTemplate'), stat: templates_count },
  ]

  return (
    <div className="max-w-7xl my-20 lg:px-8 px-6 mx-auto" {...AosAnimation.StatisticStats}>
      <h3 className="text-xl lg:text-3xl font-semibold leading-6 text-mainLightPurple">{t('titleStatistics')} </h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-4">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-mainLightPurple"><Counter duration={5} number={Number(item.stat)} /></dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
