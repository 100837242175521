"use client";

import Link from "next/link";

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl";

import { BlogList } from "./BlogList";

function BlogHomePage({data}) {

  const t = useTranslations();

  return (
    <section className="StartUpHomePage  w-full mx-auto bg-[#f5f5f5]" {...AosAnimation.BlogHomePage}>
    <div>
      <div className="bg-white">
        <div className="mx-auto ">
          <div className="mx-auto max-w-2xl text-center my-5">
            <h2 className="sm:text-[22px] sm:leading-[44px] tracking-[0.1px] text-mainDarkPurple md:text-[32px] leading-[38px] font-bold text-center">
              {t("blog_pt")}
            </h2>
            <p className="sm:text-base sm:tracking-[0.1px] mt-2 text-mainLightPurpleText text-xl text-center font-medium ">
              {t("blog_pst")}
            </p>
          </div>
          <BlogList listCount={3} data={data}  showPagination={false}/>
          <div className="mx-auto w-1/2 xmd:w-1/4 mb-20 text-center -mt-10">
            <Link href={"blog"}>
              <button
                type="button"
                className="pt-[.56rem] pb-[.56rem] text-white bg-mainLightPurple hover:bg-mainLightPurpleHover active:bg-mainLightPurpleActive text-center rounded-[8px] btnLspace w-full font-semibold opacity-100"
              >
                {t("blog_all")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default BlogHomePage;
