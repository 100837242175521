"use client";
import { useTranslations } from "next-intl";
const colorGroups = {
  blackGroup: {
    tColour: "text-black-700",
    subTColour: "text-slate-500",
  },
  whiteGroup: {
    tColour: "text-black",
    subTColour: "text-black",
  },
};
const posGroups = {
  center: {
    parentPos: "items-center",
    childPos: "text-center",
  },
  left: {
    childPos: "text-left",
  },
};
export const usePanelTitleHandler = ({
  sectionName,
  colourGroup,
  posGroup,
  bt,
}) => {
  const t = useTranslations();
  const PANEL_TITLES = {
    blog: {
      title: t("blog_pt"),
      subTitle: t("blog_pst"),
    },
    blog_details: {
      title: "Salam",
    },
    
  };
  try {
    const { title = "", subTitle = "" } = PANEL_TITLES[sectionName];
    const { tColour, subTColour } = colorGroups[colourGroup];
    const mBt = bt ? "mb-[48px]" : "";
    const { parentPos, childPos } = posGroups[posGroup];

    return {
      parentPos,
      childPos,
      title,
      subTitle,
      tColour,
      subTColour,
      mBt,
    };
  } catch (err) {
    console.log(err);
  }
};
