"use client";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

export const useLocationPath = () => {
  const location = usePathname();
  const from = useMemo(() => location, [location]);

  return { from };
};
