"use client"
import { useCallback, useEffect, useRef } from 'react';
import { getFromLocale } from '@/utils';

const useAutoLogout = ({ logoutTimeInMinutes }) => {
    const logoutTimerRef = useRef(null);
    const token = getFromLocale()
    const resetLogoutTimer = useCallback(() => {
        if (logoutTimerRef.current) {
            clearTimeout(logoutTimerRef.current);
        }
        logoutTimerRef.current = getFromLocale() && setTimeout(logout, logoutTimeInMinutes * 60 * 2000);
    }, [logoutTimeInMinutes]);

    const logout = () => {
        localStorage.removeItem('access_token');
        window.location.reload()
    };

    useEffect(() => {
        resetLogoutTimer();
        const events = ['mousedown', 'keydown', 'mousemove', 'touchstart'];

        const resetTimerOnEvent = () => {
            resetLogoutTimer();
        };

        events.forEach((event) => {
            document.addEventListener(event, resetTimerOnEvent);
        });

        return () => {
            events.forEach((event) => {
                document.removeEventListener(event, resetTimerOnEvent);
            });
            clearTimeout(logoutTimerRef.current);
        };
    }, [token, resetLogoutTimer]);

    return null;
};

export { useAutoLogout };