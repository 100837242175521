"use client";
import { setFormType } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const ToggleGroup = ({ toggleItems, sm, type, isCenter }) => {
  const dispatch = useDispatch();
  const { formType } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(setFormType({ value: toggleItems[0]?.id, type }));
  }, []);

  const marginT = sm ? "mt-4" : "mt-16";

  const classHandler = (id) => {
    return formType[type] === id
      ? `bg-mainLightPurple text-white font-medium transition-colors duration-[500ms]`
      : `text-[#94A3B8] bg-white`;
  };

  return (
    <div className={`sm:mx-[5px] md:mx-0 ${isCenter ? 'text-center' : 'text-start'} `}>
      <div
        className={`sm:w-fit sm:justify-between md:w-fit ${marginT} md:justify-start  inline-flex border border-input-border rounded-3xl`}
      >
        {toggleItems?.map(({ id, text }) => {
          return (
            <span
              key={id}
              onClick={() => dispatch(setFormType({ value: id, type }))}
              className={`sm:w-50 sm:py-3 sm:px-8 sm:text-base sm:max-w-[120px] md:py-[13px] md:w-[124px] md:text-base flex items-center ease-out justify-center bg-mainLightPurple rounded-3xl cursor-pointer ${classHandler(
                id
              )}`}
            >
              {text}
            </span>
          );
        })}
      </div>
    </div>
  );
};
