'use client'

import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useSelector } from 'react-redux';
import { getFromSession } from '@/utils';

export const  useParamID = (paramName) => {
    const searchParams = useSearchParams();
    const searchParamId = searchParams.get(paramName);
    const reduxParamId = useSelector((state) => state.template[paramName]);
    const sessionParamId = getFromSession(paramName);
    
    const [paramId, setParamId] = useState(() => {
        if (searchParamId) return searchParamId;
        if (reduxParamId) return reduxParamId;
        if (sessionParamId) return sessionParamId;
        return 'not_found';
    });

    useEffect(() => {
        if (searchParamId) {
            setParamId(searchParamId);
        } else if (reduxParamId) {
            setParamId(reduxParamId);
        } else if (sessionParamId) {
            setParamId(sessionParamId);
        } else {
            setParamId('not_found');
        }
    }, [searchParamId, reduxParamId, sessionParamId]);

    return paramId;
};

