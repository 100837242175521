"use client"
import Link from "next/link";

import { AosAnimation } from "@/constants";

import { useTranslations } from "next-intl" ;

export default function CTASection() {
    const t = useTranslations();

    return (
        <div className="bg-white overflow-hidden" {...AosAnimation.CTASection}>
            <div className="mx-auto max-w-7xl md:py-12 py-8 lg:px-8 px-4">
                <div className="relative py-12 text-center">
                    <h2 className="mx-auto  md:text-4xl text-3xl font-normal text-[#444E5E] ">
                        {t("cta-start")} <span className="text-mainLightPurple">{t("cta-span")}</span> {t("cta-end")}
                    </h2>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link href="/website" className="rounded-md bg-mainLightPurple px-16 py-2.5 text-sm font-semibold text-white shadow-xl shadow-[#0A7DFF]/30 hover:bg-mainLightPurpleHover" >
                            {t("getStart")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
